import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [];

export const dictionary = {
		"/": [~7],
		"/app/(app)": [~9,[2,3]],
		"/app/(flows)/claim-project": [24,[2,4]],
		"/app/(app)/component-showcase": [13,[2,3]],
		"/app/(app)/connect": [14,[2,3]],
		"/app/(app)/drip-lists": [15,[2,3]],
		"/app/(app)/drip-lists/all": [~17,[2,3]],
		"/app/(app)/drip-lists/[listId]": [~16,[2,3]],
		"/app/(flows)/funder-onboarding": [25,[2,4]],
		"/app/(app)/funds": [18,[2,3]],
		"/app/(app)/projects": [19,[2,3]],
		"/app/(app)/projects/all": [~21,[2,3]],
		"/app/(app)/projects/(forges)/github/[githubUsername]/[githubRepoName]": [~20,[2,3]],
		"/app/(app)/settings": [22,[2,3]],
		"/app/(app)/settings/custom-tokens": [23,[2,3]],
		"/app/utils": [26,[2]],
		"/app/(app)/[accountId]": [~10,[2,3]],
		"/app/(app)/[accountId]/tokens/[token]": [11,[2,3]],
		"/app/(app)/[accountId]/tokens/[token]/streams/[dripId]": [~12,[2,3]],
		"/blog": [~27,[5]],
		"/blog/posts/[slug]": [28,[5]],
		"/external/[url]": [29],
		"/legal/access": [30,[6]],
		"/legal/disclaimer": [31,[6]],
		"/legal/privacy": [32,[6]],
		"/[userId]": [8]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';